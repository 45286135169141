/** @jsx jsx */
import { useEffect } from 'react'
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'

import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
import Quiz from 'src/components/quiz'
import formatExerpt from 'src/utils/formatExerpt'

const Test = ({
  data: {
    allWordpressPost: { nodes },
  },
}) => {
  const post = nodes[0]

  useEffect(() => {
    setTimeout(() => {
      fetch(
        `${process.env.GATSBY_WORDPRESS_URL}wp-json/api/tbnt/update-post-views/${post.wordpress_id}`
      )
    }, 1500)
  }, [post.wordpress_id])

  return (
    <Layout>
      <SEO
        title={post.title}
        description={formatExerpt(post.excerpt)}
        image={post.featured_media?.localFile?.url}
      />
      <Quiz
        slug={post.slug}
        wordpressId={post.wordpress_id}
        PlayBuzzContainer={post.content}
      />
    </Layout>
  )
}

export default Test

export const query = graphql`
  query($id: Int!) {
    allWordpressPost(filter: { wordpress_id: { eq: $id } }) {
      nodes {
        wordpress_id
        title
        excerpt
        slug
        date
        content
        featured_media {
          localFile {
            url
            publicURL
            childImageSharp {
              fluid(maxWidth: 1080, quality: 100) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`
